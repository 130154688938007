





























































































































































































































import {
	Component, Vue
}
from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
	import SidebarSales from "@/components/sidebar-sales.vue"; // @ is an alias to /src
import SaleService from "@/services/sale-service";@
Component({
	components: {
		Header,
		SidebarSales,
	},
})
export default class GenerateInvoice extends Vue {
	project = null;
	invoice_type :any = null;
	
	trans: any = {};
	loader = false;
	option_crccd = ['CRCCD', 'WRDD', 'WRDD-Y', 'ERDD J GP Dep'];
	option_invoices = ['Progress Service Order', 'Final Payment', 'Partial Payment', 'Retention', 'Other']
	option_banks = ['Riyadh Bank'];
	options_project = [
		'Aramco-Riyadh',  
		'Aramco-YANBU', 
		'Aramco-JUAYMAH', 
		'Aramco-Jeddah',
		'Yasref', 
		'Marafiq', 
		'KJO', 
		'Bahria', 
		'Grain-Sailos', 
		'Tanajeeb',
		'Milling-company',
		'Air-fueling',
		'Air-fueling-Makkah',
		'Air-fueling-Riyadh',
		'Air-fueling-Shirqiya',
		'Air-fueling-Aseer',
		'Tameed',
		'Mastoura'
		];
	option_customers = [
		'SAUDI ARABIAN OIL COMPANY', 
		'YANBU ARAMCO SINOPEC REFINING COMPANY Ltd. (YASREF)', 
		'Marafiq', 
		'Tanajeeb', 
		'KJO', 
		'Bahria', 
		'Grain-Sailos',
		'Al-Ahsa',
		'Qaseem',
		'Tabuk',
		'Jeddah',
		'Al-Kharj',
		'Al-Dammam',
		'Medina-Munawara',
		'Al-Riyadh',
		'Jizan',
		'Hail',
		'Jamoom',
		'Al-Jouf',
		'Khamis Musheet',
		'Air-fueling',
		'Tameed'
		];
	invoice_id = '';
	option_covered_period :any = [];	
	items = [{
		sno: 1,
		item: "",
		item_arabic: "",
		datehijri : '',
		dategeo : '',
		unit: 0,
		price: 0,
		req_quantity: 0,
		quantity: 0,
		amount: 0,
		absent: 0,
		absent_deduction: 0		
	} ];
	
  public contract_start = "";
	public contract_end = "";
	public invoice_start = "";
	public invoice_end = "";
	public payment_due = "";
	
	changedValue() {}
	change_project() {}
	changedValueInvoice() {}
	saveinvoice() {
		this.loader = true;
			this.trans['project'] = this.project;
			this.trans['contract_start'] = this.contract_start;
			this.trans['contract_end'] = this.contract_end;
			this.trans['invoice_start'] = this.invoice_start;
			this.trans['invoice_end'] = this.invoice_end;
			this.trans['payment_due'] = this.payment_due;
			this.trans['type'] = 'Credit';
			this.trans['ref_invoice_id'] = this.invoice_id;

			if(this.invoice_type == 'Other'){
				this.trans['invoice_type'] = this.trans.invoice_others;
			}else{
				this.trans['invoice_type'] = this.invoice_type;
			}
			let obj = {
				invoice: this.trans,
				items: this.items
			}
			SaleService.credit(obj).then((response) => {
				let data = response.data;
				this.loader = false;
				if(this.project == 'Aramco-Riyadh') {
					this.$router.push({ path: `/invoice/sales-invoice-riyadh-credit/${data.invoice}` });
				}else if(this.project == 'Aramco-YANBU') {
				this.$router.push({ path: `/invoice/sales-invoice-yumbu-credit/${data.invoice}` });
				}
				// else if(this.project == 'Aramco-JUAYMAH') {
				// this.$router.push({ path: `/invoice/sales-invoice-juaymah/${data.invoice}` });
				// }
				else if(this.project == 'Aramco-Jeddah') {
				this.$router.push({ path: `/invoice/sales-invoice-jeddah-credit/${data.invoice}` });
				}
				// else if(this.project == 'Yasref') {
				// this.$router.push({ path: `/invoice/sales-invoice-yasref/${data.invoice}` });
				// }else if(this.project == 'Marafiq') {
				// 	this.$router.push({ path: `/invoice/sales-invoice-marafiq/${data.invoice}` });
				// }
				// else if(this.project == 'KJO') {
				// 	this.$router.push({ path: `/invoice/sales-invoice-kjo/${data.invoice}` });
				// }
				else if(this.project == 'Tanajeeb') {
					this.$router.push({ path: `/invoice/sales-invoice-tanajeeb-credit/${data.invoice}` });
				}
				else if(this.project == 'Mastoura') {
					this.$router.push({ path: `/invoice/sales-invoice-aramco-mastoura-credit/${data.invoice}` });
				}
				// else if(this.project == 'Bahria') {
				// 	this.$router.push({ path: `/invoice/sales-invoice-bahria/${data.invoice}` });
				// }
				// else if(this.project == 'Grain-Sailos') {
				// 	this.$router.push({ path: `/invoice/sales-invoice-grain-sailos/${data.invoice}` });
				// }
				// else if(this.project == 'Milling-company') {
				// 	this.$router.push({ path: `/invoice/sales-invoice-milling/${data.invoice}` });
				// }
				// else if(this.project == 'Air-fueling-Makkah') {
				// 	this.$router.push({ path: `/invoice/sales-invoice-air-fueling-makkah/${data.invoice}` });
				// }
				// else if(this.project == 'Air-fueling-Riyadh') {
				// 	this.$router.push({ path: `/invoice/sales-invoice-air-fueling-riyadh/${data.invoice}` });
				// }
				// else if(this.project == 'Air-fueling-Shirqiya') {
				// 	this.$router.push({ path: `/invoice/sales-invoice-air-fueling-shirqiya/${data.invoice}` });
				// }
				// else if(this.project == 'Air-fueling-Aseer') {
				// 	this.$router.push({ path: `/invoice/sales-invoice-air-fueling-aseer/${data.invoice}` });
				// }				
				// else if(this.project == 'Tameed') {
				// 	this.$router.push({ path: `/invoice/sales-invoice-tameed/${data.invoice}` });
				// }
			}).catch((e) => {
				this.loader = false;
				console.log(e);
			});
	}
	add_item() {
		this.items.push({
			sno: this.items.length + 1,
			item: "",
			item_arabic: "",
			datehijri: "",
			dategeo: "",
			price: 0,
			unit: 0,
      		req_quantity: 0,
			quantity: 0,
			amount: 0,
			absent: 0,
			absent_deduction: 0
		});
	}
	getsetting(){
		SaleService.getcovered().then((response :any) => {
				// this.option_covered_period = response.data;
				response.data.forEach((x:any) => {
					let obj:any = {
						value: x.id,
						text : x.item
					}
					this.option_covered_period.push(obj)
					
				});
				// this.loader = false;
				// this.$router.push({ path: `/invoice/sales-invoice/${data.invoice}` });
			}).catch((e: any) => {
				this.loader = false;
				console.log(e);
			});
	}

	getInvoice(){
		SaleService.get_invoice(this.invoice_id).then((response) => {
			let data = response.data;
			this.trans = data.sales[0];
			this.items = data.items
			this.project = this.trans['project'];
			this.contract_start = this.trans['contract_start'];
			this.contract_end = this.trans['contract_end'];
			this.invoice_start = this.trans['invoice_start'];
			this.invoice_end = this.trans['invoice_end'];
			this.payment_due = this.trans['payment_due'];
			
			
			if(this.trans['invoice_type'] == 'Other'){
				this.invoice_type = 'Other'
				this.trans.invoice_others = this.trans['invoice_type'];
				
			}else{
				this.invoice_type = this.trans['invoice_type'] ;
			}

			this.loader = false;
		}).catch((e) => {
			this.loader = false;
			console.log(e);
		});
	}
	mounted(){
		this.getsetting()
	}
}
